<template>
    <v-sheet elevation="2">
        
        <div class="pa-3">
            <v-text-field
                label="C.I.P."
                v-model.number='cip'
                type="number"
                :disabled="loadingPac"
                outlined
            />
            <v-sheet elevation="2" class="pa-2" v-if="datos.id">

                <v-scroll-y-transition>
                    <v-alert color="primary" text v-if="datos.internado">
                        Este paciente tiene internamientos activos
                    </v-alert>
                </v-scroll-y-transition>

                <v-text-field
                    label="Paciente"
                    v-model='datos.nombreCompleto'
                    readonly
                />
                <v-text-field
                    label="Fecha de nacimiento"
                    v-model='datos.fechaNacimiento'
                    readonly
                />
                <v-text-field
                    label="C.U.R.P."
                    v-model='datos.curp'
                    readonly
                />
                <v-textarea
                    label="Observaciones"
                    v-model='datos.observaciones'
                    readonly
                />
                <div>Creado: {{datos.dateCreated}} por {{datos.creador}}</div>
                <div v-if="datos.modificador">Última modificación: {{datos.lastUpdated}} por {{datos.modificador}}</div>
            </v-sheet>
            <v-sheet class="mt-2" elevation="2">
                <v-skeleton-loader
                    v-if="loadingPac"
                    type="list-item-two-line, list-item-two-line, list-item-two-line"

                />
                <v-alert type="warning" text v-if="!loadingPac && detalles.length == 0">
                    No hay registros para mostrar
                </v-alert>
                <v-treeview
                    v-if="!loadingPac && detalles.length > 0"
                    activatable
                    :items="detalles"
                    item-key="id"
                    item-text="text"
                    item-children="entradas"
                >
                    <template v-slot:prepend="{item}">
                        <span v-if="item.total">
                            ({{item.total}})
                        </span>
                    </template>

                    <template v-slot:label="{item}">
                        <span v-if="item.folio">
                            Folio {{item.folio}} <span v-if="item.text"> de fecha {{item.text}}</span>
                        </span>
                        <span v-if="!item.folio">
                            {{item.text}}
                        </span>
                    </template>

                </v-treeview>
                
            </v-sheet>
        </div>

    </v-sheet>
</template>

<script>
export default {
    props:{
        datos:Object,
        paciete:Number,
        loadingPac:Boolean,
        detalles:Array,
    },
    data:()=>({
        
    }),
    computed:{
        cip:{
            get(){
                return this.paciente
            },
            set(v){
                this.$emit('update:paciente', v)
            }
        },
    },
}
</script>

<style>

</style>